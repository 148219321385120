import React, {  } from 'react'
import { Publication } from "src/assembled"
import { Footer } from 'src/components'
import { SEO } from "src/components/utils"


export default function PublicationPage({ location }) {
    // seo
    const seo = <SEO
    title="Publication"
    description="디어젠이 발표한 논문들을 소개합니다. 해당 논문들은 JMLR, Nature Scientific Reports 등의 저명한 학술지에 게재되었습니다." 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <Publication />
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}